import { createRouter, createWebHistory } from "vue-router";
import Segments from "../modules/Segments";
const { PageVisit, PageVisitNonLoggedIn } = Segments();
import Auth from "../modules/Auth";
const { isLoggedIn, isDistributor } = Auth();
const routes = [
  {
    path: "/",
    redirect: {
      name: "Home",
    },
    component: () =>
      import(
        /* webpackChunkName: "default-layout" */
        "@/views/layouts/DefaultLayout.vue"
      ),
    children: [
      {
        path: "/login",
        name: "Login",
        component: () =>
          import(
            /* webpackChunkName: "login-page" */
            "@/views/pages/authentication/Login.vue"
          ),
        meta: {
          restrict: true, // it will go on this route when only user loggout
          PageTitle: "Login to Medusa Distribution",
        },
      },
      {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: () =>
          import(
            /* webpackChunkName: "forgot-password" */
            "@/views/pages/authentication/ForgotPassword.vue"
          ),
        meta: {
          restrict: true, // it will go on this route when only user loggout
          PageTitle: "Forgot Password to Medusa Distribution",
        },
      },
      {
        path: "/reset/:email/:token",
        name: "ChangePassword",
        component: () =>
          import(
            /* webpackChunkName: "change-password" */
            "@/views/pages/authentication/ChangePassword.vue"
          ),
        meta: {
          restrict: true, // it will go on this route when only user loggout
          PageTitle: "Change Password to Medusa Distribution",
        },
      },
      {
        path: "/admin/invoice/:id",
        name: "Admin Invoice",
        component: () =>
          import(
            /* webpackChunkName: "invoice" */
            "@/views/pages/invoice/Invoice.vue"
          ),
        meta: {
          PageTitle: "Invoice | Medusa Distribution",
        },
      },
      {
        path: "/invoice/:id",
        name: "Invoice",
        component: () =>
          import(
            /* webpackChunkName: "invoice" */
            "@/views/pages/invoice/Invoice.vue"
          ),
        meta: {
          PageTitle: "Invoice | Medusa Distribution",
        },
      },
      {
        path: "/packing-list/:id",
        name: "Packing List",
        component: () =>
          import(
            /* webpackChunkName: "packing-list" */
            "@/views/pages/invoice/PackingList.vue"
          ),
        meta: {
          PageTitle: "Packing List | Medusa Distribution",
        },
      },
      {
        path: "/invoice-ui",
        name: "InvoiceUI",
        component: () =>
          import(
            /* webpackChunkName: "invoiceui" */
            "@/views/pages/invoice/Invoiceui.vue"
          ),
        meta: {
          PageTitle: "Invoice | Medusa Distribution",
        },
      },
      {
        path: "/downloadInvoices/:id",
        name: "Download Invoices",
        component: () => import("@/views/pages/invoice/MultipleInvoices.vue"),
        meta: {
          PageTitle: "Download Invoices",
        },
      },
    ],
  },
  {
    path: "/",
    component: () =>
      import(
        /* webpackChunkName: "default-layout" */
        "@/views/layouts/DashboardLayout.vue"
      ),
    children: [
      {
        path: "/home",
        name: "Home",
        component: () =>
          import(
            /* webpackChunkName: "home-page" */
            "@/views/pages/home/Home.vue"
          ),
        meta: {
          analytics: true,
          PageTitle: "Medusa Distribution - Vape and Smoke Shop Wholesale Distributor",
        },
      },
      {
        path: "/brands",
        name: "Brands",
        component: () =>
          import(
            /* webpackChunkName: "brand-page" */
            "@/views/pages/brand/Brands.vue"
          ),
        meta: {
          analytics: true,
          PageTitle: "All Brands at Medusa",
        },
      },
      {
        path: "/brand",
        redirect: {
          name: "Brands",
        },
      },
      {
        path: "/brand/:slug",
        name: "BrdSlug",
        component: () =>
          import(
            /* webpackChunkName: "brand-page" */
            "@/views/pages/brand/Brand.vue"
          ),
        meta: {
          analytics: true,
          PageTitle: "params",
        },
      },
      {
        path: "/favorites",
        name: "Watchlist",
        component: () =>
          import(
            /* webpackChunkName: "watchlist-page" */
            "@/views/pages/watchlist/Watchlist.vue"
          ),
        meta: {
          analytics: true,
          PageTitle: "Medusa Product Favorites",
        },
      },
      {
        path: "/order-details/:id",
        name: "OrderDetails",
        component: () =>
          import(
            /* webpackChunkName: "order-details-page" */
            "@/views/pages/checkout/OrderDetails.vue"
          ),
        meta: {
          analytics: true,
          restrict: true, // it will go on this route when only user Login
          PageTitle: "Medusa Order Detail",
        },
      },
      {
        path: "/account",
        name: "Account",
        redirect: {
          name: "Orders",
        },
        component: () =>
          import(
            /* webpackChunkName: "account-page" */
            "@/views/pages/account/Account.vue"
          ),
        children: [
          {
            path: "orders",
            name: "Orders",
            component: () =>
              import(
                /* webpackChunkName: "account-page" */
                "@/views/pages/account/modules/Orders.vue"
              ),
            meta: {
              analytics: true,
              restrict: true, // it will go on this route when only user login
              PageTitle: "Medusa Account Details",
            },
          },
          {
            path: "stores",
            name: "Stores",
            component: () =>
              import(
                /* webpackChunkName: "account-page" */
                "@/views/pages/account/modules/Stores.vue"
              ),
            meta: {
              analytics: true,
              restrict: true, // it will go on this route when only user login
              PageTitle: "Medusa Account Details",
            },
          },
          {
            path: "payments",
            name: "Payments",
            component: () =>
              import(
                /* webpackChunkName: "account-page" */
                "@/views/pages/account/modules/Payments.vue"
              ),
            meta: {
              analytics: true,
              restrict: true, // it will go on this route when only user login
              PageTitle: "Medusa Account Details",
            },
          },
          {
            path: "users",
            name: "Users",
            component: () =>
              import(
                /* webpackChunkName: "account-page" */
                "@/views/pages/account/modules/Users.vue"
              ),
            meta: {
              analytics: true,
              restrict: true, // it will go on this route when only user login
              PageTitle: "Medusa Account Details",
            },
          },
        ],
      },
      // UI Pages
      {
        path: "/ui-brand",
        name: "UIBrand",
        component: () =>
          import(
            /* webpackChunkName: "home-page" */
            "@/views/pages/brand/Brand.vue"
          ),
        meta: {
          analytics: true,
        },
      },

      {
        path: "/categories",
        name: "Categories",
        component: () =>
          import(
            /* webpackChunkName: "categories-page" */
            "@/views/pages/category/Categories.vue"
          ),
        meta: {
          analytics: true,
          PageTitle: "All Categories at Medusa",
        },
      },
      {
        path: "/category",
        redirect: {
          name: "categories",
        },
      },
      {
        path: "/category/:slug",
        name: "CatSlug",
        component: () =>
          import(
            /* webpackChunkName: "category-page" */
            "@/views/pages/category/Category.vue"
          ),
        meta: {
          analytics: true,
          PageTitle: "params",
        },
      },
      {
        path: "/collection/:slug",
        name: "Slug",
        component: () =>
          import(
            /* webpackChunkName: "collection-page" */
            "@/views/pages/collection/Collection.vue"
          ),
        meta: {
          analytics: true,
          PageTitle: "params",
        },
      },
      {
        path: "/trending",
        name: "Trending",
        component: () =>
          import(
            /* webpackChunkName: "trending-page" */
            "@/views/pages/trending/Trending.vue"
          ),
        meta: {
          analytics: true,
          PageTitle: "Trending Products at Medusa",
        },
      },
      {
        path: "/test/:email/trending",
        name: "TrendingCustom",
        component: () =>
          import(
            /* webpackChunkName: "trending-page" */
            "@/views/pages/trending/Trending.vue"
          ),
        meta: {
          analytics: true,
          PageTitle: "Trending Products at Medusa",
        },
        beforeEnter(to, from, next) {
          let email = localStorage.getItem("email");
          if (email && email.includes("@medusadistribution.com")) {
            next();
          } else {
            next("/404");
          }
        },
      },
      {
        path: "/coming-soon",
        name: "Coming Soon",
        component: () =>
          import(
            /* webpackChunkName: "trending-page" */
            "@/views/pages/comingsoon/Comingsoon.vue"
          ),
        meta: {
          analytics: true,
          PageTitle: "Coming Soon to Medusa",
        },
      },
      {
        path: "/clearance",
        name: "Clearance",
        component: () =>
          import(
            /* webpackChunkName: "trending-page" */
            "@/views/pages/clearance/Clearance.vue"
          ),
        meta: {
          analytics: true,
          PageTitle: "Clearance to Medusa",
        },
      },
      {
        path: "/lightning-order",
        name: "Lightning",
        component: () =>
          import(
            /* webpackChunkName: "lightning-page" */
            "@/views/pages/lightning/Lightning.vue"
          ),
        meta: {
          analytics: true,
          PageTitle: "Medusa Lightning Order",
        },
      },
      {
        path: "/search",
        name: "Search2",
        component: () =>
          import(
            /* webpackChunkName: "search-page" */
            "@/views/pages/search/Search2.vue"
          ),
        meta: {
          analytics: true,
          PageTitle: "params",
        },
      },
      {
        path: "/products/:slug",
        name: "Product",
        component: () =>
          import(
            /* webpackChunkName: "product-page" */
            "@/views/pages/product/Product.vue"
          ),
        meta: {
          analytics: true,
        },
      },
      {
        path: "/bundles/:slug",
        name: "ProductBundles",
        component: () =>
          import(
            /* webpackChunkName: "product-page" */
            "@/views/pages/product/Product.vue"
          ),
        meta: {
          analytics: true,
        },
      },
    ],
  },
  // Checkout Layout
  {
    path: "/",
    component: () =>
      import(
        /* webpackChunkName: "checkout-layout" */
        "@/views/layouts/CheckoutLayout.vue"
      ),
    children: [
      {
        path: "/checkout",
        name: "Checkout",
        component: () =>
          import(
            /* webpackChunkName: "checkout-page" */
            "@/views/pages/checkout/Checkout.vue"
          ),
        meta: {
          analytics: true,
          PageTitle: "Medusa Distribution Secure Checkout",
        },
      },
    ],
  },
  // Navbar Layout
  {
    path: "/",
    component: () =>
      import(
        /* webpackChunkName: "navbar-layout" */
        "@/views/layouts/NavbarLayout.vue"
      ),
    children: [
      {
        path: "/thankyou/:id",
        name: "Thankyou",
        component: () =>
          import(
            /* webpackChunkName: "home-page" */
            "@/views/pages/checkout/Thankyou.vue"
          ),
        meta: {
          PageTitle: "Thank You From Medusa",
        },
      },
      {
        path: "/download-invoices/:id",
        name: "DownloadInvoices",
        component: () =>
          import(
            /* webpackChunkName: "invoices-download" */
            "@/views/pages/account/GeneratedInvoices.vue"
          ),
        meta: {
          PageTitle: "Download Invoices - Medusa",
        },
      },
      {
        path: "/join-us",
        name: "Join",
        component: () =>
          import(
            /* webpackChunkName: "forgot-password" */
            "@/views/pages/authentication/JoinMedusa.vue"
          ),
        meta: {
          restrict: true, // it will go on this route when only user loggout
          PageTitle: "Join Medusa",
        },
      },
      {
        path: "/set-password/:email/:token",
        name: "SetPassword",
        component: () =>
          import(
            /* webpackChunkName: "set-password" */
            "@/views/pages/authentication/SetPassword.vue"
          ),
        meta: {
          PageTitle: "Set Password - Medusa",
        },
      },
      {
        path: "/404",
        name: "404",
        component: () => import(/* webpackChunkName: "error-404" */ "@/views/pages/404.vue"),
        meta: {
          PageTitle: "Medusa Couldn't Found",
        },
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: () => import(/* webpackChunkName: "privacy-policy" */ "@/views/pages/PrivacyPolicy.vue"),
        meta: {
          PageTitle: "Privacy Policy",
        },
      },
      {
        path: "/terms-and-conditions",
        name: "Terms",
        component: () => import(/* webpackChunkName: "terms-and-conditions" */ "@/views/pages/Terms.vue"),
        meta: {
          PageTitle: "Terms And Conditions",
        },
      },
    ],
  },
  // No sidebar Layout
  {
    path: "/",
    component: () =>
      import(
        /* webpackChunkName: "nosidebar-layout" */
        "@/views/layouts/TopNavLayout.vue"
      ),
    children: [
      {
        path: "/distro",
        name: "Distro",
        component: () =>
          import(
            /* webpackChunkName: "distro-page" */
            "@/views/pages/distro/Index.vue"
          ),
        meta: {
          PageTitle: "Medusa",
          restrict: true, // it will go on this route when only user login
        },
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/404",
  },
];

const notAllowedDistributorRoutes = [
  '/',
  '/home',
  '/brands',
  '/categories',
  '/coming-soon',
  '/clearance',
  '/trending',
  'search',
  'lightning-order',
  /^\/brand\/.+$/,
  /^\/category\/.+$/,
  /^\/collection\/.+$/,
  /^\/bundles\/.+$/,
  /^\/products\/.+$/,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL || "localhost:8080"),
  routes,
  scrollBehavior() {
    return {
      top: 0,
      behavior: "smooth",
    };
  },
});
router.beforeEach((to, from, next) => {
  const isRestrictedRoute = notAllowedDistributorRoutes.some((route) => {
    if (typeof route === 'string') {
      return route === to.path;
    } else if (route instanceof RegExp) {
      return route.test(to.path);
    }
    return false;
  });

  if (to.meta.restrict) {
    if (isLoggedIn()) {
      if (isDistributor() && isRestrictedRoute) {
        next({ path: '/distro' });
      } else if (['Login', 'Join', 'ForgotPassword'].includes(to.name)) {
        next({ path: '/' });
      } else if (to.name === 'ChangePassword') {
        // remove everything from local storage
        localStorage.clear();
        next();
      } else {
        next();
      }
    } else {
      if (['Login', 'Join', 'ForgotPassword', 'ChangePassword'].includes(to.name)) {
        next();
      } else {
        next({ path: '/' });
      }
    }
  } else {
    if (isDistributor() && isRestrictedRoute) {
      next({ path: '/distro' });
    } else {
      next();
    }
  }
});

router.afterEach((to, from, next) => {
  if (to.meta.analytics && isLoggedIn()) PageVisit(to);
  else PageVisitNonLoggedIn(to);

  if (to.meta.PageTitle) {
    if (to.meta.PageTitle == "params") {
      const name = (n) => {
        return n
          .replace(/-/g, " ")
          .split(" ")
          .map((l) => l.slice(0, 1).toUpperCase() + l.slice(1))
          .join(" ");
      };
      if (to.name == "Search") {
        document.getElementsByTagName("title")[0].innerHTML = `Search results for ${to.query.q} at Medusa`;
      } else if (to.name == "Slug") {
        document.getElementsByTagName("title")[0].innerHTML = `${name(to.params.slug)} at Medusa - Wholesale`;
      } else if (to.name == "CatSlug") {
        document.getElementsByTagName("title")[0].innerHTML = `${name(to.params.slug)} at Medusa - Wholesale`;
      } else if (to.name == "BrdSlug") {
        document.getElementsByTagName("title")[0].innerHTML = `${name(to.params.slug)} at Medusa - Wholesale`;
      }
    } else document.getElementsByTagName("title")[0].innerHTML = to.meta.PageTitle;
  }
});

export default router;
